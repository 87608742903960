.main-content {
  background: rgb(54,65,84);
  background: linear-gradient(180deg, rgba(54,65,84,1) 0%, rgba(27,33,45,1) 100%);

  padding-top: 48px;
  padding-bottom: 55px;

  .section-title {
    color: #ffffff;
    font-size: 48px;

    @screen lg {
      width: 400px;
    }
  }
}