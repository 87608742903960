.why-use {
  background: #F6F9FC;
  padding-top: 86px;
  padding-bottom: 86px;

  .question {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }

  .title-section {
    font-size: 28px;
    font-weight: bold;

    @screen lg {
      font-size: 36px;
    }

    @screen xl {
      font-size: 38px;
    }
  }

  .part-found-and-not-found {
    img {
      width: 85px;

      @screen md {
        width: 125px;
      }

      @screen lg {
        width: 170px;
      }

    }

  }

  .five-m {
    img {
      width: 66px;

      @screen md {
        width: 100px;
      }

      @screen lg {
        width: 133px;
      }
    }
  }

  .substitute {
    img {
      width: 44px;

      @screen md {
        width: 66px;
      }

      @screen lg {
        width: 80px;
      }
    }
  }
}