.part-tabs {
  .tab-tools {
    margin-top: 40px;

    .tab-list {
      padding: 0;
    }

    .tab-item {
      background: #B9C6D6;

    }

    .tab-cursor {
      //background: #E2E9F1;
    }

    .tab-panels {
      //background: #E2E9F1;
      >div {
        padding: 24px 17px;

        @screen lg {
          padding: 48px 33px;
        }
      }
    }

    .tab-content {
      font-size: 16px;
      color: #283546;
    }
  }
}