.main-layout-header {
  background: red;
  background: linear-gradient(180deg, rgba(66,81,102,1) 0%, rgba(63,77,98,1) 100%);
  //background: red;

  &.bom-quote-header{
    background: #FFFFFF;
  }


  .logo {
    height: 27px;
  }


  .menu {

  }

  .button-login {
    font-size: 14px;
    background: #FFFFFF;
    height: 33px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 800;
  }
}

//3B485B

//364355

//1a222d